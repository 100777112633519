<template>
    <Layout>
        <div class="row" id="page">

            <div class="card w-100">
                <div class="card-body d-flex position-relative">
                    <div>
                        <img :src="mentee.profile_image_base64" alt="" class="rounded-circle" height="150">
                    </div>
                    <div class="my-auto w-100">
                        <span class="ml-3 d-block fs-30 font-weight-bold mb-3">{{ mentee.name }}</span>
                        <div class="row">
                            <div class="col-md-6">
                                    <span class="ml-3 d-block" v-if="mentee.email">
                                        <i class="fa fa-envelope"></i>
                                        <span class="ml-2 fs-14">{{ mentee.email }}</span>
                                    </span>
                                <span class="ml-3 d-block" v-if="mentee.profession">
                                        <i class="fa fa-briefcase"></i>
                                        <span class="ml-2 fs-14">{{ mentee.profession }}</span>
                                    </span>
                            </div>
                            <div class="col-md-3 d-flex justify-content-around my-auto">

                                <a v-if="mentee.instagram" target="_blank"
                                   :href="'https://www.instagram.com/' + mentee.instagram">
                                    <i class="fab fa-instagram-square fa-2x"></i>
                                </a>

                                <a v-if="mentee.linkedin" target="_blank"
                                   :href="'https://www.linkedin.com/in/' + mentee.linkedin">
                                    <i class="fab fa-linkedin fa-2x"></i>
                                </a>

                                <a v-if="mentee.youtube" target="_blank"
                                   :href="'https://www.youtube.com/' + mentee.youtube">
                                    <i class="fab fa-youtube fa-2x"></i>
                                </a>

                            </div>
                        </div>
                        <router-link v-if="mentee.id" :to="'/mensagens/' + mentee.id">
                            <span class="position-absolute bg-primary text-center" style="    top: 10px;
                                    right: 10px;
                                    border-radius: 50%;
                                    width: 40px;
                                    height: 40px;
                                    vertical-align: center;"
                                  title="Enviar Mensagem"
                            >
                                <i class="fa fa-comment-alt text-white" style="margin-top: 0.85rem"></i>
                            </span>
                        </router-link>
                    </div>
                </div>
                <div class="p-3">
                    <div class="row">
                        <div class="col">
                            {{ mentee.description }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-100 mb-4">
                <Mentoring
                    @refresh-graphic="getDataGraphic"
                />
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from "@/components/layout/main.vue";
import Mentoring from "@/views/mentee/mentoring.vue";
import http from '@/http'
import {endLoading, startLoading} from "@/components/composables/spinners";

export default {

    components: {
        Layout,
        Mentoring,
    },

    data() {
        return {
            mentee: {},
            completed: 0,
            total: 1
        }
    },
    methods: {
        getData() {
            this.$store.commit('api/setSpinner', true);
            startLoading('page');

            const id = this.$route.params.mentee;
            const verify = this.$store.state.mentee.mentee;

            if (verify.id == id) {
                this.mentee = verify;
                this.$store.commit('api/setSpinner', false);
                endLoading('page');
            } else {
                http.get('mentees/data/' + id)
                    .then(response => {
                        this.mentee = response.data.data[0];
                        this.$store.state.mentee.mentee = this.mentee;
                    })
                    .finally(() => {
                        this.$store.commit('api/setSpinner', false);
                        endLoading('page');
                    })
            }
        },

        getDataGraphic() {
            const formData = {
                mentee: this.$route.params.mentee,
                id: this.$route.params.mentoring
            }
            http.post('tasks/completed-by-mentee', formData)
                .then(response => {
                    this.completed = response.data.completed;
                    this.total = response.data.total;
                })
                .catch(e => {
                    console.error('Get Data Tasks Mentoring: ', e)
                })
        }
    },
    mounted() {
        this.getData();
        this.getDataGraphic();
    }
}

</script>

<style>

.fs-30 {
    font-size: 30px;
}

</style>
