<template>
    <div>
        <div class="card w-100">
            <div class="p-3">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="`width: ${sections.progress}%;`"
                         :aria-valuenow="parseInt(sections.progress)" aria-valuemin="0" aria-valuemax="100">
                        {{ sections.progress.replace('.', ',') }}%
                    </div>
                </div>
            </div>
        </div>

        <b-card v-for="section in sections" :key="section.id" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-card-title block v-b-toggle="`accordion-${section.id}`"
                              class="p-3 d-flex justify-content-between">
                    {{ section.title }}
                    <div v-if="section.tasks.length === 0">
                        <i class="fa fa-lock text-muted" title="Sessão bloqueada" @click="setTasks(section.id)"></i>
                    </div>
                </b-card-title>
            </b-card-header>
            <b-collapse :id="`accordion-${section.id}`" :accordion="'my-accordion' + section.id" role="tabpanel" visible
                        v-if="section.tasks.length > 0">
                <b-card-body>
                    <b-card-text v-for="task in section.tasks" :key="'task-list-' + task.id"
                                 class="d-flex justify-content-between">
                            <span>
                            {{ task.contents.subject }}
                            </span>

                        <div>
                            <router-link
                                :to="task.contents.type === 3 ? `${mentee}/formulario/${task.contents.id}/${task.contents.form_id}` :  `${mentee}/atividade/${task.contents.id}`">
                                <i class="mx-1 fa fa-eye pointer text-muted" title="Visualizar Tarefa"
                                   v-if="task.status !== 1 && (task.contents.type === 3 || task.contents.type === 4)"></i>
                            </router-link>
                            <i class="mx-1 fa fa-times-circle text-danger" title="Tarefa Pendente"
                               v-if="task.status === 1"></i>
                            <i class="mx-1 fa fa-exclamation-circle text-warning"
                               title="Tarefa Aguardando Validação" v-else-if="task.status === 2"></i>
                            <i class="mx-1 fa fa-check-circle text-success" title="Tarefa Finalizada" v-else></i>
                        </div>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>

import http from '@/http'

export default {
    data() {
        return {
            sections: [],
            mentee: this.$route.params.mentee,
            slug: this.$route.params.slug,
            completed: 0,
            total: 1
        }
    },

    methods: {
        async getData() {

            const mentee = this.$route.params.mentee;
            const mentoring = this.$route.params.mentoring;

            const verify = this.$store.state.mentee.tasks;

            if (Object.keys(verify).length > 0 && verify.mentee === mentee && verify.mentoring === mentoring) {
                this.sections = verify.sections;
            } else {
                const formData = {
                    mentee,
                    id: mentoring
                }
                http.post('mentorings/tasks-by-mentee/', formData)
                    .then(response => {
                        this.sections = response.data.mentoring.sections;
                        this.sections.progress = response.data.progress;
                        this.$store.commit('mentee/setTasks', {
                            sections: this.sections,
                            mentoring,
                            mentee
                        });
                    })
                    .catch(e => {
                        console.error('Get Tasks Mentoring: ', e)
                    })
            }
        },

        setTasks(section) {
            const formData = {
                mentee: this.$route.params.mentee,
                id: this.$route.params.mentoring,
                section,
            }

            http.post('tasks/generate', formData)
                .then(() => {
                    this.$notifySuccess('Sessão liberada com sucesso!');
                    this.getData();
                    this.$emit('refresh-graphic');
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
        }
    },

    mounted() {
        this.getData();
    }
}

</script>
